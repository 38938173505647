.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
.navbar {
    background-color: #161A1E;
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar h1 {
    margin: 0;
  }
  
  .navbar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

  }
  
  .navbar li {
    margin-left: 1rem;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
  }
  
  .navbar a:hover {
    text-decoration: underline;
  }

  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .active-link {
    font-weight: bold;
    border-bottom: 2px solid white;
  }
    
  .main {
    flex: 1;
    padding: 1rem;
  }
  
  .footer {
    background-color: #161A1E;
    color: white;
    text-align: center;
    padding: 1rem;
    margin-top: auto;
  }
  
  .build-list, .build-details-page, .overview-page {
    padding: 2rem;
    font-family: Arial, sans-serif;
  }
  
  .build-list h2, .build-details-page h2, .overview-page h2{
    margin-bottom: 1rem;
  }
  
  .build-list .controls {
    margin-bottom: 1rem;
  }
  
  .build-list ul {
    list-style: none;
    padding: 0;
  }
  
  .build-item {
    background-color: #f9f9f9;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .build-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .build-item a {
    text-decoration: none;
    color: #333;
  }
  
  .build-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .build-header h3 {
    margin: 0;
  }
  
  .build-header span {
    color: #666;
  }
  
  .build-details {
    margin-top: 0.5rem;
  }
  
  .build-details p {
    margin: 0.25rem 0;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .pagination button {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s;
  }
  
  .pagination button:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
  }
  
  .pagination button.active {
    background-color: #333;
    color: #fff;
    border-color: #333;
  }
  
  .build-details-page .overview-page .build-info {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }
  
  .build-details-page .overview-page .build-info p {
    margin: 0.5rem 0;
  }
  
  .overview-page {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }

  .test-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  
  .test-table th, .test-table td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
  }
  
  .test-table th {
    background-color: #f2f2f2;
  }
  
  .test-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .test-table a {
    color: #333;
    text-decoration: none;
  }
  
  .test-table a:hover {
    text-decoration: underline;
  }
  
  .search-sort-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .search-sort-controls input {
    width: 60%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .search-sort-controls select {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .table tr:hover {
    background-color: #f1f1f1;
  }
  
  .table a {
    color: #333;
    text-decoration: none;
  }
  
  .table a:hover {
    text-decoration: underline;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .test-list-page {
    padding: 2em;
  }
  
  .search-bar {
    margin-bottom: 1em;
  }
  
  .search-bar input {
    padding: 0.5em;
    width: 100%;
    max-width: 400px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .test-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1em;
  }
  
  .test-card {
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .test-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .test-card h3 {
    margin: 0;
    font-size: 1.2em;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .spinner {
    height: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
  }


.auth-buttons {
  margin-left: auto;
}

.auth-buttons button {
  background-color: #F1F7FE;
  color: #010E19;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.auth-buttons button:hover {
  background-color: #fff;
}

.controls {
  padding: 0.5em;
}

.benchmark-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Adjust this value to control the spacing between items */
}

.benchmark-item {
  flex: 0 0 calc(25% - 10px); /* Adjust the width percentage as needed */
  box-sizing: border-box;
  padding: 5px;
}

@media (max-width: 1200px) {
  .benchmark-item {
    flex: 0 0 calc(33.33% - 10px); /* Adjust the width percentage as needed */
  }
}

@media (max-width: 992px) {
  .benchmark-item {
    flex: 0 0 calc(50% - 10px); /* Adjust the width percentage as needed */
  }
}

@media (max-width: 768px) {
  .benchmark-item {
    flex: 0 0 100%;
  }
}

.benchmark-item label {
  display: block;
  padding: 8px 12px;
  background-color: #f2f2f2;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.benchmark-item label:hover {
  background-color: #e6e6e6;
}